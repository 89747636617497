import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BookingsState } from '../reducers/bookings.reducer';
import { BOOKINGS_KEY } from '../keys';
import { BOOKING_TYPES } from '../../enums';

const selectState = createFeatureSelector<BookingsState>(BOOKINGS_KEY);

export const selectBookings = createSelector(selectState, (state) => [
    ...(state.passengers?.map((item) => ({ item, kind: BOOKING_TYPES.PASSENGER })) || []),
    ...(state.parcels?.map((item) => ({ item, kind: BOOKING_TYPES.PARCEL })) || []),
]);

export const selectBooking = (id: string) =>
    createSelector(selectState, (state) => [...(state.passengers || []), ...(state.parcels || [])].find((booking) => booking.id === id) || null);

export const selectParcel = (id: string) => createSelector(selectState, (state) => (state.parcels || []).find((parcel) => parcel.id === id) || null);
export const selectPassenger = (id: string) =>
    createSelector(selectState, (state) => (state.passengers || []).find((passenger) => passenger.id === id) || null);

export const selectBookingsUnassigned = createSelector(selectState, (state) => [
    ...(state.passengers
        ?.filter((passenger) => passenger.filter === null && passenger.isResigned !== true)
        .map((item) => ({ item, kind: BOOKING_TYPES.PASSENGER })) || []),
    ...(state.parcels?.filter((parcel) => parcel.filter === null).map((item) => ({ item, kind: BOOKING_TYPES.PARCEL })) || []),
]);
export const selectBookingsResigned = createSelector(selectState, (state) => [
    ...(state.passengers?.filter((passenger) => passenger.isResigned === true).map((item) => ({ item, kind: BOOKING_TYPES.PASSENGER })) || []),
    ...(state.parcels?.filter((parcel) => parcel.isResigned === true).map((item) => ({ item, kind: BOOKING_TYPES.PARCEL })) || []),
]);
export const selectBookingsResignedIds = createSelector(selectState, (state) => [
    ...(state.passengers?.filter((passenger) => passenger.isResigned === true).map((item) => item.id) || []),
    ...(state.parcels?.filter((parcel) => parcel.isResigned === true).map((item) => item.id) || []),
]);
export const selectBookingsDidntGo = createSelector(selectState, (state) => [
    ...(state.passengers?.filter((passenger) => passenger.didntGo === true).map((item) => ({ item, kind: BOOKING_TYPES.PASSENGER })) || []),
    ...(state.parcels?.filter((parcel) => parcel.didntGo === true).map((item) => ({ item, kind: BOOKING_TYPES.PARCEL })) || []),
]);
export const selectBookingsDidntGoIds = createSelector(selectState, (state) => [
    ...(state.passengers?.filter((passenger) => passenger.didntGo === true).map((item) => item.id) || []),
    ...(state.parcels?.filter((parcel) => parcel.didntGo === true).map((item) => item.id) || []),
]);
export const selectPassengers = createSelector(selectState, (state) => state.passengers || []);
export const selectPassengersUnassigned = createSelector(selectState, (state) => state.passengers?.filter((passenger) => passenger.filter === null) || []);
export const selectParcels = createSelector(selectState, (state) => state.parcels || []);
export const selectParcelsUnassigned = createSelector(selectState, (state) => state.parcels?.filter((parcels) => parcels.filter === null) || []);
export const selectLoaded = createSelector(selectState, (state) => state.loaded);
export const selectLoading = createSelector(selectState, (state) => state.loading);
export const selectPassengerById = (id: string) => createSelector(selectState, (state) => state.passengers?.find((passenger) => passenger.id === id) || null);
export const selectPassengersByIds = (ids: string[]) =>
    createSelector(selectState, (state) => state.passengers?.filter((passenger) => ids.includes(passenger.id)) || null);
export const selectParcelById = (id: string) => createSelector(selectState, (state) => state.parcels?.find((parcel) => parcel.id === id) || null);
export const selectParcelsByIds = (ids: string[]) => createSelector(selectState, (state) => state.parcels?.filter((parcel) => ids.includes(parcel.id)) || null);

import { Component, ChangeDetectionStrategy, Input, inject, OnDestroy } from '@angular/core';
import { WindowService } from '@app/services/services/window.service';
import { SidebarMenuService } from '@app/shared/services/sidebar.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-box',
    templateUrl: './box.component.html',
    styleUrls: ['./box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoxComponent implements OnDestroy {
    @Input() title?: string;
    @Input() padding = true;
    @Input() background = true;
    @Input() border = false;
    @Input() shadow = true;
    @Input() allowSidebarMenu = false;

    private readonly sidebarMenuService = inject(SidebarMenuService);
    private readonly windowService = inject(WindowService);

    readonly isSidebarMenu$ = this.sidebarMenuService.sidebarMenu$;

    subscription$ = new Subscription();

    ngOnDestroy() {
        this.subscription$.unsubscribe();
    }

    onChangeToSidebarMenu() {
        this.sidebarMenuService.sidebarMenu$.next(!this.sidebarMenuService.sidebarMenu$.value);
        this.windowService.triggerResize();
    }
}

import { Injectable } from '@angular/core';
import { debounceTime, fromEvent, map, merge, startWith } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WindowService {
    scroll$ = fromEvent(window, 'scroll');
    resize$ = fromEvent(window, 'resize');

    change$ = merge(this.scroll$, this.resize$).pipe(startWith(true));

    height$ = this.change$.pipe(
        map(() => window.innerHeight),
        debounceTime(50),
    );

    scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    triggerResize() {
        window.dispatchEvent(new Event('resize'));
    }
}
